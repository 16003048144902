import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { BaseZappsmithStorePermissions, CommonFunctions, MdlBaseObject, UserStore } from '@dicorp/zappsmith-ngx-core';
import { ZappsmithWebService, ZappsmithWebServiceApiParams } from '@dicorp/zappsmith-ngx-core';
import { BaseZappsmithBoard, BaseZappsmithStoreState, BaseZappsmithComponentStore } from '@dicorp/zappsmith-ngx-core';

import { EditorOptions } from '@dicorp/html-ffe';

import { EntityMap, FfeEditorOptions } from 'src/common';
import { AppAuthService } from '@dicorp/zappsmith-ngx-auth';
import { GridState } from './zapp-grid-view-state.store';

function getZappAppBoardParams(): ZappsmithWebServiceApiParams {
    const apiParams = new ZappsmithWebServiceApiParams('zapp_board');
    apiParams.setQuery({ projection: { 'tracking_info': 0 } });
    return apiParams;
}

export class ZappAppBoard extends BaseZappsmithBoard {
    public Label: string;
    public PluralLabel: string;

    public SortOrder: number;

    public Kind: string;
    public MainPackage: string;

    public ApplicationLink: MdlBaseObject;
    public ApplicationLinkId: string;
    public ApplicationLinkValue: string;

    public Enabled: boolean;
    public IsDisabled: boolean;
    public IsHidden: boolean;

    public Parents: ZappAppBoardParent[] = [];
    public ParentLinkValues: string[] = [];

    public Style: string;

    public permissions: BaseZappsmithStorePermissions;
    public permission_base: string;
    public collection: string;

    public manifest: ZappAppBoardManifest;

    public dataset: string;
    public add_dataset: string;
    public summary_dataset: string;
    public rm_dataset: string;

    public AddBoard: ZappAppBoard;

    public entityMap: EntityMap;
    public entityMapOverrides: EntityMap;

    public recordManagerLabel: string;
    public recordManagerPath: string;
    public recordManagerRecord: any = {};

    public viewRecordPath: string;
    public editRecordPath: string;
    public addRecordPath: string;

    public detailsPath: string;

    public children: ZappAppBoardChild[] = [];
    public lastSelectedChildIndex: number;

    public boardAdditionalActions: BoardAdditionalActions;
    public actionRules: BoardActionRule[];
    public actionsLabel: string;

    public disableDefaultActionRules: boolean;
    public disableExport: boolean;
    public disableColumnsAndViews: boolean;

    public doubleClickActionOverride?(entity: any): Promise<any>;
    public diGetRowStyle?(field_name: string, row: any): any;

    public _extra: any;

    // Static board properties
    public object_name: string;
    public versionObjectOverride: string;
    public versionObjectPath: string;
    public ExternalReferenceKey: string;

    public facilityPermission: string;

    public static_board: boolean;
    public add_board: any;

    public hideDetailsAction: boolean;

    public rulesActive: boolean;
    public datasetFieldLookup: string;

    public disable_actions?: boolean;
    public prevent_delete: boolean;
    public prevent_add: boolean;

    public columnDefSetting: string;
    public columnDefs: any[];

    // Board State properties
    public gridState: GridState = {
        defaultViewNeedsLoading: true
    };

    public get isParent(): boolean {
        return this.children?.length > 0;
    }

    public get gridViewsKey(): any {
        return this.static_board ? this.object_name : '/zapp/' + this._id;
    }

    public get ffeEditorOptions(): FfeEditorOptions {
        return this.buildFfeEditorOptions(this);
    }

    constructor(rawZappAppBoard: any) {
        super(rawZappAppBoard, 'Board.Id', 'Board.Name');
        this.Id = this.cleanKey(this.Id);

        // Board Properties
        const boardLabel = CommonFunctions.resolvePathValue('Board.Label', rawZappAppBoard);
        this.Label = boardLabel ? boardLabel : this.Id;
        this.PluralLabel = CommonFunctions.resolvePathValue('Board.PluralLabel', rawZappAppBoard);

        this.SortOrder = CommonFunctions.resolvePathValue('Application.SortOrder', rawZappAppBoard);

        this.Kind = CommonFunctions.resolvePathValue('Board.Kind', rawZappAppBoard);
        this.MainPackage = CommonFunctions.resolvePathValue('Board.MainPackage', rawZappAppBoard);

        this.ApplicationLink = CommonFunctions.resolvePath('Board.ApplicationLink', rawZappAppBoard);
        this.ApplicationLinkId = CommonFunctions.getMdlObjectId(this.ApplicationLink);
        this.ApplicationLinkValue = CommonFunctions.getMdlObjectValue(this.ApplicationLink);
        this.ApplicationLinkId = this.cleanKey(this.ApplicationLinkId);

        this.IsDisabled = CommonFunctions.resolvePathValue('Board.IsDisabled', rawZappAppBoard);
        this.IsHidden = CommonFunctions.resolvePathValue('Board.IsHidden', rawZappAppBoard);
        this.Enabled = !this.IsDisabled && !this.IsHidden;

        this.recordManagerLabel = this.Label ? this.Label : this.Id;
        this.recordManagerLabel += ' Manager';

        const rawParents: any[] = CommonFunctions.resolvePath('Board.Parents', rawZappAppBoard);
        this.Parents = rawParents?.map(rawParent => {
            return new ZappAppBoardParent(rawParent);
        });
        this.ParentLinkValues = this.Parents?.map(parent => {
            return parent.ParentBoardLinkValue;
        });

        this.Style = CommonFunctions.resolvePathValue('Board.Style', rawZappAppBoard);

        // Raw Properties
        this.dataset = CommonFunctions.resolvePath('dataset', rawZappAppBoard);
        this.permission_base = CommonFunctions.resolvePath('permission_base', rawZappAppBoard);
        this.collection = CommonFunctions.resolvePath('collection', rawZappAppBoard);
        this.rulesActive = CommonFunctions.resolvePath('rulesActive', rawZappAppBoard);
        this.datasetFieldLookup = CommonFunctions.resolvePath('datasetFieldLookup', rawZappAppBoard);

        this.add_dataset = CommonFunctions.resolvePath('add_dataset', rawZappAppBoard);
        this.add_board = CommonFunctions.resolvePath('add_board', rawZappAppBoard);

        this.summary_dataset = CommonFunctions.resolvePath('summary_dataset', rawZappAppBoard);
        this.rm_dataset = CommonFunctions.resolvePath('rm_dataset', rawZappAppBoard);

        const manifest = CommonFunctions.resolvePath('manifest', rawZappAppBoard);
        this.manifest = new ZappAppBoardManifest(manifest);

        this.entityMapOverrides = CommonFunctions.resolvePath('entityMapOverrides', rawZappAppBoard);

        this._extra = rawZappAppBoard._extra;

        const object_name = CommonFunctions.resolvePath('object_name', rawZappAppBoard);

        if (object_name) {
            this.object_name = object_name;
        } else if (this.ApplicationLinkId && this.Id) {
            this.object_name = this.ApplicationLinkId + '/' + this.Id;
        } else if (this.ApplicationLinkId && !this.Id) {
            this.object_name = this.ApplicationLinkId;
        } else if (!this.ApplicationLinkId && this.Id) {
            this.object_name = this.Id;
        }

        this.versionObjectOverride = CommonFunctions.resolvePath('versionObjectOverride', rawZappAppBoard);
        this.ExternalReferenceKey = CommonFunctions.resolvePath('ExternalReferenceKey', rawZappAppBoard);

        this.static_board = CommonFunctions.resolvePath('static_board', rawZappAppBoard);
        this.facilityPermission = CommonFunctions.resolvePath('facilityPermission', rawZappAppBoard);

        this.hideDetailsAction = CommonFunctions.resolvePath('hideDetailsAction', rawZappAppBoard);

        this.disable_actions = CommonFunctions.resolvePath('disable_actions', rawZappAppBoard);
        this.prevent_delete = CommonFunctions.resolvePath('prevent_delete', rawZappAppBoard);
        this.prevent_add = CommonFunctions.resolvePath('prevent_add', rawZappAppBoard);

        const columnDefSetting = CommonFunctions.resolvePath('columnDefSetting', rawZappAppBoard);
        this.columnDefSetting = columnDefSetting ? columnDefSetting : this.dataset;

        this.actionRules = this.createBoardActionsRules(CommonFunctions.resolvePath('Board.ActionRules', rawZappAppBoard));

        this.disableDefaultActionRules = CommonFunctions.resolvePath('disableDefaultActionRules', rawZappAppBoard);
        this.disableExport = CommonFunctions.resolvePath('disableExport', rawZappAppBoard);
        this.disableColumnsAndViews = CommonFunctions.resolvePath('disableColumnsAndViews', rawZappAppBoard);
    }

    public setBoardRoutes(): void {
        this.recordManagerPath = 'zapp/' + this.ApplicationLinkId + '/' + this.Id;

        if (this.permissions.canView()) {
            this.viewRecordPath = this.recordManagerPath + '/View';
        }
        if (this.permissions.canEdit()) {
            this.editRecordPath = this.recordManagerPath + '/Edit';
        }
        if (this.permissions.canAdd()) {
            this.addRecordPath = this.recordManagerPath + '/Add';
        }

        this.detailsPath = this.recordManagerPath + '/Details';
    }

    private cleanKey(k: string): string {
        return k?.replace(/[\. ,:-]+/g, "-");
    }

    private createBoardActionsRules(rawBoardActionRules: any[]): BoardActionRule[] {
        return rawBoardActionRules?.map(rawBoardActionRule => {
            return new BoardActionRule(rawBoardActionRule);
        });
    }

    private buildFfeEditorOptions(zappAppBoard: ZappAppBoard): FfeEditorOptions {
        const editorOptions: EditorOptions = new EditorOptions();
        editorOptions.allow_print = zappAppBoard.manifest.allow_print;
        editorOptions.hide_save = zappAppBoard.manifest.hide_save;
        editorOptions.hide_save_and_exit = zappAppBoard.manifest.hide_save_and_exit
        editorOptions.save_label = zappAppBoard.manifest.save_label;
        editorOptions.save_and_exit_label = zappAppBoard.manifest.save_and_exit_label;
        editorOptions.check_label = zappAppBoard.manifest.check_label;
        editorOptions.cancel_label = zappAppBoard.manifest.cancel_label;
        editorOptions.hideNavBar = zappAppBoard.manifest.hide_nav_bar;

        const ffeEditorOptions: FfeEditorOptions = {
            editorOptions,
            baseObject: !zappAppBoard.static_board ? "/zapp/" + zappAppBoard._id : '/' + zappAppBoard.object_name,
            dataSet: zappAppBoard.dataset,
            label: zappAppBoard.Label ? zappAppBoard.Label : zappAppBoard.Id,
            entityMap: zappAppBoard.entityMap,
            permissionBaseName: zappAppBoard.permission_base ? zappAppBoard.permission_base : zappAppBoard.collection,
            rulesActive: zappAppBoard.rulesActive === false ? false : true,
            datasetFieldLookup: zappAppBoard.datasetFieldLookup
        };

        ffeEditorOptions.headers = [{ label: ffeEditorOptions.label }];
        ffeEditorOptions.forceReloadAfterSave = zappAppBoard.manifest.force_reload_after_save;

        return ffeEditorOptions;
    }
}

export class ZappAppBoardParent {
    public ParentBoardLink: MdlBaseObject;
    public ParentBoardLinkValue: string;

    public ParentKeyField: string;
    public ParentKeyType?: string;

    public DisableActions?: boolean;
    public DisableChildAdd?: boolean;

    private currentParentKeyValue = new Subject<string>();
    currentParentKeyValue$ = this.currentParentKeyValue.asObservable();

    constructor(rawZappAppBoardParent: any) {
        this.ParentBoardLink = CommonFunctions.resolvePath('ParentBoardLink', rawZappAppBoardParent);
        this.ParentBoardLinkValue = CommonFunctions.resolvePathValue('ParentBoardLink', rawZappAppBoardParent);

        this.ParentKeyField = CommonFunctions.resolvePathValue('ParentKeyField', rawZappAppBoardParent);
        this.ParentKeyType = CommonFunctions.resolvePathValue('ParentKeyType', rawZappAppBoardParent);

        this.DisableActions = CommonFunctions.resolvePathValue('DisableActions', rawZappAppBoardParent);
        this.DisableChildAdd = CommonFunctions.resolvePathValue('DisableChildAdd', rawZappAppBoardParent);
    }

    public setCurrentParentKeyValue(value: string) {
        this.currentParentKeyValue.next(value);
    }
}

export class ZappAppBoardChild {
    constructor(public zappAppBoard: ZappAppBoard, public zappAppBoardParent: ZappAppBoardParent) {
    }
}

interface ZappAppBoardState extends BaseZappsmithStoreState {
    zappAppBoards?: ZappAppBoard[];
    zappAppBoardsLoaded?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ZappAppBoardStore extends BaseZappsmithComponentStore<ZappAppBoardState> {
    private zappAppBoards?: ZappAppBoard[] = [];
    readonly zappAppBoards$: Observable<ZappAppBoard[]> = this.select(state => state.zappAppBoards);

    private zappAppBoardsLoaded?: boolean;
    readonly zappAppBoardsLoaded$: Observable<boolean> = this.select(state => state.zappAppBoardsLoaded);

    constructor(private zappsmithWebService: ZappsmithWebService,
        private userStore: UserStore,
        private appAuthService: AppAuthService) {
        super();
        this.setState({});

        this.appAuthService.activeUser$.subscribe(activeUser => {
            if (activeUser) {
                const zappAppBoardParams = getZappAppBoardParams();
                zappAppBoardParams.setOrderBy({ 'Board.SortOrder.#value': 'asc' });

                this.zappsmithWebService.getApi(zappAppBoardParams).then(
                    result => {
                        this.addZappAppBoards(this.createZappAppBoards(result));
                        this.zappAppBoardsLoaded = true;

                        this.patchState({
                            zappAppBoardsLoaded: this.zappAppBoardsLoaded
                        });
                    },
                    () => {
                    });
            }
        })
    }

    public getZappAppBoard(object_name: string): ZappAppBoard {
        return this.zappAppBoards?.find(board => {
            return board.object_name === object_name;
        });
    }

    public getZappAppBoardById(_id: string): ZappAppBoard {
        return this.zappAppBoards?.find(board => {
            return board._id === _id;
        });
    }

    public getZappAppBoardsByIds(_ids: string[]): ZappAppBoard[] {
        return this.zappAppBoards?.filter(board => {
            return _ids?.indexOf(board._id) >= 0;
        });
    }

    public getZappAppBoards(): ZappAppBoard[] {
        return this.zappAppBoards;
    }

    public createZappAppBoard(data: any): ZappAppBoard {
        return this.createZappAppBoards([data])[0];
    }

    public addZappAppBoards(zappAppBoards: ZappAppBoard[]): void {
        this.zappAppBoards = this.zappAppBoards.concat(zappAppBoards);
        this.patchState({
            zappAppBoards: this.zappAppBoards
        });
    }

    public createZappAppBoards(data: any[]): ZappAppBoard[] {
        const zappAppBoardResult = this.convertGetBoardResults(ZappAppBoard, data);
        this.setBoardProperties(zappAppBoardResult.data);
        return zappAppBoardResult.data;
    }

    public setBoardProperties(zappAppBoards: ZappAppBoard[]): void {
        for (var zappAppBoard of zappAppBoards) {
            zappAppBoard.entityMap = this.buildEntityMap(zappAppBoard);
            zappAppBoard.permissions = this.buildAppBoardPermission(zappAppBoard);
            zappAppBoard._extra = this.buildBGExtraOptionsThatNobodyUnderstands(zappAppBoard);
            zappAppBoard.setBoardRoutes();
        }

        for (var zappAppBoard of zappAppBoards) {
            zappAppBoard.children = zappAppBoards.filter(zappAppBoardChild => {
                return zappAppBoardChild.ParentLinkValues?.indexOf(zappAppBoard._id) >= 0;
            }).map(zappAppBoardChild => {
                const zappAppBoardParent = zappAppBoardChild.Parents.find(parent => {
                    return parent.ParentBoardLinkValue === zappAppBoard._id;
                });
                return new ZappAppBoardChild(zappAppBoardChild, zappAppBoardParent);
            });

            if (zappAppBoard.AddBoard) {
                this.setBoardProperties([zappAppBoard.AddBoard]);
            }
        }
    }

    private buildEntityMap(zappAppBoard: ZappAppBoard): EntityMap {
        const entityMap = this.getBGHardCodedSecretEntityMap(zappAppBoard.facilityPermission);

        if (zappAppBoard.entityMapOverrides) {
            for (var k in zappAppBoard.entityMapOverrides) {
                entityMap[k] = zappAppBoard.entityMapOverrides[k];
            }
        }

        const manifest_children: any[] = zappAppBoard.manifest && zappAppBoard.manifest.children;
        if (manifest_children) {
            manifest_children.forEach(function (mc) {
                if (mc.kind == "MANIFEST_EntityLink") {
                    const alias_name = mc.alias_name ? mc.alias_name : mc.object_name;
                    let map_item: any = {
                        base: mc.base ? mc.base : 'zapp/' + mc.object_name,
                        id: mc.map_id ? mc.map_id : mc.object_name + ".Id.#value",
                        control_number: mc.map_link ? mc.map_link : "_id",
                        name: mc.map_name ? mc.map_name : mc.object_name + ".Name.#value",
                        type_link_code: mc.map_type_link_code ? mc.map_type_link_code : mc.object_name + ".Type.#value",
                        type_link_code_type: mc.map_type_link_code_type ? mc.map_type_link_code_type : "string",
                        facility_link: mc.map_facility_link ? mc.map_facility_link : "primary_facility_key",
                        is_disabled: mc.map_is_disabled ? mc.map_is_disabled : null,
                        is_hidden: mc.map_is_hidden ? mc.map_is_hidden : null,
                        dataset_name: mc.dataset_name ? mc.dataset_name : null,
                        top_level_path: mc.top_level_path ? mc.top_level_path : null,
                        default_add_path: mc.default_add_path ? mc.default_add_path : null,
                        permission_base_name: mc.permission_base_name ? mc.permission_base_name : null,
                        alias_name: alias_name,
                        object_name: mc.object_name
                    };
                    if (mc.additional_parameters) {
                        map_item.additional_parameters = mc.additional_parameters;
                    }
                    if (mc.needed_fields) {
                        if (!map_item.additional_parameters) {
                            map_item.additional_parameters = {}
                        }
                        //add the additional_parameters to a list
                        const d: { [key: string]: number; } = { '_id': 1 }
                        mc.needed_fields.split(',').forEach((v: string) => {
                            d[v.trim()] = 1;
                        })
                        map_item.additional_parameters['projection'] = JSON.stringify(d)
                    }
                    entityMap[alias_name] = map_item;
                }
            });
        }

        return entityMap;
    }

    private getBGHardCodedSecretEntityMap(facilityPermission: string = "FacilitySearchLevel"): EntityMap {
        const entityMap: EntityMap = {
            'facility': {
                'base': 'filtered_facility',
                'id': 'id',
                'control_number': 'key',
                'name': 'name',
                'type_link_code': 'type',
                'additional_parameters': { 'permission_level_flag': facilityPermission }
            },
            'staff': {
                'id': 'Staff.Id.#value',
                'control_number': '_id',
                'name': 'Staff.Name.#value',
                'type_link_code': 'Staff.Type.#value'
            },
            'app_user': {
                'id': 'User.Id.#value',
                'control_number': '_id',
                'name': 'User.Name.#value',
                'base': 'user_no_admin',
                permissionBaseName: 'user',
            },
            'role': {
                'id': 'Role.Id.#value',
                'control_number': '_id',
                'name': 'Role.Name.#value',
                'base': 'filtered_role'
            },
            'dataset': {
                'id': 'ds_name',
                'control_number': '_id',
                'name': 'ds_name',
                'base': 'dataset_store'
            },
            'menu_info': {
                'id': 'id',
                'control_number': 'key',
                'name': 'name',
                'base': 'dataset/menu_info'
            },
            'template_tag_group': {
                'id': 'TemplateTagGroup.Id.#value',
                'control_number': '_id',
                'name': 'TemplateTagGroup.Name.#value'
            },
            'template_document': {
                'id': 'TemplateDocument.Id.#value',
                'control_number': '_id',
                'name': 'TemplateDocument.Name.#value',
                'type_link_code':
                    'TemplateDocument.Type.#value'
            },
            'image': {
                'base': 'image',
                'id': "Image.Id.#value",
                'control_number': "_id",
                'name': "Image.Name.#value",
                'type_link_code': "Image.Type.#value",
                'type_link_code_type': "string",
                'facility_link': "primary_facility_key",
                'dataset_name': "Image",
                'top_level_path': "Image",
                'permission_base_name': "Image",
                'object_name': "image"
            },
            'zapp_app': {
                'id': 'Application.Id.#value',
                'control_number': '_id',
                'name': 'Application.Name.#value',
                'base': 'zapp_app',
            },
            'zapp_board': {
                'id': 'Board.Id.#value',
                'control_number': '_id',
                'name': 'Board.Name.#value',
                'base': 'zapp_board',
            },
            'zapp_doc': {
                'id': 'Document.Id.#value',
                'control_number': '_id',
                'name': 'Document.Name.#value',
                'base': 'zapp_doc',
            },
            'zapp_custom_toolbar': {
                'control_number': '_id',
                'name': 'ZappCustomToolbar.Label.#value',
                'id': 'ZappCustomToolbar.Label.#value',
                'base': 'zapp_custom_toolbar',
            }
        };

        return entityMap;
    }

    private buildBGExtraOptionsThatNobodyUnderstands(zappAppBoard: ZappAppBoard): any {
        const permissionBaseName = !!(zappAppBoard.permission_base) ? zappAppBoard.permission_base : zappAppBoard.collection;

        return {
            'entityMap': zappAppBoard.entityMap,
            'baseObject': zappAppBoard.ffeEditorOptions.baseObject,
            'permissionBaseName': permissionBaseName,
            'label': zappAppBoard.Label,
            headers: [{ label: zappAppBoard.Label, path: [] }],
        }
    }

    private buildAppBoardPermission(zappAppBoard: ZappAppBoard): BaseZappsmithStorePermissions {
        if (zappAppBoard.Id) {
            return new BaseZappsmithStorePermissions(
                zappAppBoard.ApplicationLinkId,
                zappAppBoard.Id,
                this.userStore.activeUserCredentials);
        } else {
            return new BaseZappsmithStorePermissions(
                zappAppBoard.ApplicationLinkId,
                null,
                this.userStore.activeUserCredentials);
        }
    }
}

export class ZappAppBoardManifest {
    add_frm: boolean;
    add_rul: boolean;

    allow_anonymous_add: boolean;
    allow_print: boolean;
    allow_save_and_add: boolean;

    save_label: string;
    save_and_exit_label: string;
    check_label: string;
    cancel_label: string;

    children: any[];

    coldef: string;
    collection: string;
    dataset: string;

    force_reload_after_save: boolean;

    frm: string;

    hide_save: boolean;
    hide_save_and_exit: boolean;
    hide_nav_bar: boolean;

    icon: string;
    kind: string;
    mdl: string;
    mnu: string;
    name: string;
    notes: string;
    permission_base: string;

    post_modify: string;
    post_query: string;
    pre_modify: string;
    pre_query: string;
    rul: string;
    style: string;
    xref: string;

    constructor(manifest: any) {
        Object.assign(this, manifest);

        this.allow_print = manifest?.allow_print === "True";
        this.allow_save_and_add = manifest?.allow_save_and_add === "True";

        this.hide_save = manifest?.hide_save === "True";
        this.hide_save_and_exit = manifest?.hide_save_and_exit === "True";
        this.hide_nav_bar = manifest?.hide_nav_bar === "True";

        this.force_reload_after_save = manifest?.force_reload_after_save === "True";

        this.save_label = manifest?.save_label ? manifest?.save_label : null;
        this.save_and_exit_label = manifest?.save_and_exit_label ? manifest?.save_and_exit_label : null;
        this.check_label = manifest?.check_label ? manifest?.check_label : null;
        this.cancel_label = manifest?.cancel_label ? manifest?.cancel_label : null;
    }
}

export interface BoardAdditionalAction {
    label: string;
    refresh?: boolean;

    action(doc_id: string, selectedRows?: any[]): Promise<boolean>;
}

export interface BoardAdditionalActions {
    [key: string]: BoardAdditionalAction;
}

export class BoardActionRule {
    rule?: string;
    label?: string;

    confirmationMessage?: string;
    restrictPermission?: string;

    isEmptyRowsAllowed?: boolean;

    standAlone?: boolean;

    refresh?: boolean;
    refreshChildren?: boolean;

    actionOverride?(currentKeyValues: string[], selectedRows?: any[]): Promise<any>;

    constructor(rawBoardActionRule: any) {
        this.rule = CommonFunctions.resolvePathValue('Rule', rawBoardActionRule);
        this.label = CommonFunctions.resolvePathValue('Label', rawBoardActionRule);

        this.confirmationMessage = CommonFunctions.resolvePathValue('ConfirmationMessage', rawBoardActionRule);

        this.isEmptyRowsAllowed = CommonFunctions.resolvePathValue('IsEmptyRowsAllowed', rawBoardActionRule);
        this.restrictPermission = CommonFunctions.resolvePathValue('RestrictPermission', rawBoardActionRule);
    }
}